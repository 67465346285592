import React, { useState, useEffect, useRef } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import SignatureCanvas from "react-signature-canvas";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"; // Asegúrate de importar TableContainer aquí


const RegistroContacto = () => {
  const [contactos, setContactos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [roles, setRoles] = useState([]);
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    id_empresa: "",
    id_rol: "",
    firma: "",
  });

  const signatureRef = useRef(null); // Referencia para la firma

  // Cargar empresas y roles
  useEffect(() => {
    const fetchData = async () => {
      try {
        const empresasSnapshot = await getDocs(collection(db, "empresas"));
        const rolesSnapshot = await getDocs(collection(db, "rol"));

        setEmpresas(empresasSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setRoles(rolesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    fetchData();
  }, []);

  // Obtener contactos con datos de empresa y rol
  const fetchContactos = async () => {
    try {
      const contactosSnapshot = await getDocs(collection(db, "contactos"));
      const contactosList = [];

      for (let contactoDoc of contactosSnapshot.docs) {
        const contactoData = contactoDoc.data();
        let empresaNombre = "Desconocida";
        let rolNombre = "Desconocido";

        if (contactoData.id_empresa) {
          const empresaDoc = await getDoc(doc(db, "empresas", contactoData.id_empresa));
          if (empresaDoc.exists()) {
            empresaNombre = empresaDoc.data().nombre;
          }
        }

        if (contactoData.id_rol) {
          const rolDoc = await getDoc(doc(db, "rol", contactoData.id_rol));
          if (rolDoc.exists()) {
            rolNombre = rolDoc.data().nombre;
          }
        }

        contactosList.push({
          id: contactoDoc.id,
          ...contactoData,
          empresa: empresaNombre,
          rol: rolNombre,
        });
      }

      setContactos(contactosList);
    } catch (error) {
      console.error("Error al obtener contactos:", error);
    }
  };

  useEffect(() => {
    fetchContactos();
  }, []);

  // Manejar cambios en el formulario
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Guardar firma en Base64
  const handleSaveSignature = () => {
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      const firmaBase64 = signatureRef.current.toDataURL("image/png");
      setFormData({ ...formData, firma: firmaBase64 });
    } else {
      alert("Por favor firme antes de guardar.");
    }
  };

  // Limpiar firma
  const handleClearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      setFormData({ ...formData, firma: "" });
    }
  };

  // Agregar contacto
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contactos"), {
        nombre: formData.nombre,
        apellido: formData.apellido,
        correo: formData.correo,
        telefono: formData.telefono,
        id_empresa: formData.id_empresa,
        id_rol: formData.id_rol,
        firma: formData.firma,
      });

      setFormData({
        nombre: "",
        apellido: "",
        correo: "",
        telefono: "",
        id_empresa: "",
        id_rol: "",
        firma: "",
      });

      signatureRef.current.clear();
      fetchContactos();
    } catch (error) {
      console.error("Error al agregar contacto:", error);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Gestión de Contactos
      </Typography>

      {/* Formulario para agregar contactos */}
      <form onSubmit={handleSubmit}>
        <TextField label="Nombre" name="nombre" value={formData.nombre} onChange={handleChange} fullWidth required margin="normal" />
        <TextField label="Apellido" name="apellido" value={formData.apellido} onChange={handleChange} fullWidth required margin="normal" />
        <TextField label="Correo" name="correo" value={formData.correo} onChange={handleChange} fullWidth required margin="normal" />
        <TextField label="Teléfono" name="telefono" value={formData.telefono} onChange={handleChange} fullWidth required margin="normal" />

        {/* Selección de empresa */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Empresa</InputLabel>
          <Select name="id_empresa" value={formData.id_empresa} onChange={handleChange} required>
            {empresas.map((empresa) => (
              <MenuItem key={empresa.id} value={empresa.id}>
                {empresa.nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Selección de rol */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Rol</InputLabel>
          <Select name="id_rol" value={formData.id_rol} onChange={handleChange} required>
            {roles.map((rol) => (
              <MenuItem key={rol.id} value={rol.id}>
                {rol.nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Firma con touch */}
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Firma (Toca para firmar)
        </Typography>
        <Paper elevation={3} style={{ border: "1px solid #ccc", padding: "10px" }}>
          <SignatureCanvas ref={signatureRef} penColor="black" canvasProps={{ width: 400, height: 200, className: "sigCanvas" }} />
        </Paper>

        <Button onClick={handleSaveSignature} variant="contained" color="primary" style={{ marginTop: "10px", marginRight: "10px" }}>
          Guardar Firma
        </Button>
        <Button onClick={handleClearSignature} variant="contained" color="secondary" style={{ marginTop: "10px" }}>
          Limpiar Firma
        </Button>

        <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: "20px" }}>
          Agregar Contacto
        </Button>
      </form>

      {/* Mostrar la lista de contactos */}
      <Typography variant="h5" gutterBottom style={{ marginTop: "40px" }}>
        Lista de Contactos
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Correo</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Rol</TableCell>
              <TableCell>Firma</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactos.map((contacto) => (
              <TableRow key={contacto.id}>
                <TableCell>{`${contacto.nombre} ${contacto.apellido}`}</TableCell>
                <TableCell>{contacto.correo}</TableCell>
                <TableCell>{contacto.telefono}</TableCell>
                <TableCell>{contacto.empresa}</TableCell>
                <TableCell>{contacto.rol}</TableCell>
                <TableCell>
                  {contacto.firma ? (
                    <img src={contacto.firma} alt="Firma" style={{ width: "100px", height: "50px" }} />
                  ) : (
                    <Typography variant="body2">Sin firma</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default RegistroContacto;
