import React, { useState } from "react";
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";  
import RegistroContactos from "../pages/RegistroContactos";
import RegistroEmpresa from "../pages/RegistroEmpresa";
import RegistroUnidadTipoUnidad from "../pages/RegistroUnidadTipoUnidad";
import RegistroServicios from "../pages/RegistroServiciosTipoServicios";
import RegistroRoles from "../pages/RegistroRol";
import Cotizacion from "../pages/Cotizacion"; 

const drawerWidth = 240;

const Dashboard = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [content, setContent] = useState("dashboard"); // Estado del contenido
  const navigate = useNavigate();
  const { logout } = useAuth(); 

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    await logout();
    navigate("/"); // Redirigir al usuario después de cerrar sesión
  };

  // Función que actualizará el estado "content" desde el Sidebar
  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", bgcolor: "#f4f6f8" }}>
      <CssBaseline />

      {/* AppBar */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "#1976d2",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            Dashboard CRM
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Sidebar */}
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { width: drawerWidth },
          }}
        >
          <Sidebar onContentChange={handleContentChange} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": { width: drawerWidth },
          }}
          open
        >
          <Sidebar onContentChange={handleContentChange} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        </Drawer>
      </Box>

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        {/* Renderiza el contenido basado en el estado "content" */}
        {content === "dashboard" && <Box sx={{ height: "100vh" }}>Contenido del Dashboard</Box>}
        {content === "RegistroContactos" && <RegistroContactos />}
        {content === "RegistroEmpresa" && <RegistroEmpresa />}
        {content === "RegistroUnidadTipoUnidad" && <RegistroUnidadTipoUnidad />}
        {content === "RegistroServicios" && <RegistroServicios />}
        {content === "RegistroRoles" && <RegistroRoles />}
        {content === "Cotizacion" && <Cotizacion />}
      </Box>
    </Box>
  );
};

export default Dashboard;
