import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Box, Typography, Alert, Card, CardContent, Link } from "@mui/material";
import { LOGO_BASE64 } from "../components/constants";
import Register from "./Register";

const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [isRegister, setIsRegister] = useState(false);

    const handleLogin = () => {
        if (email && password) {
            login({ email, password })
                .then(() => {
                    setMessage("✅ Login exitoso!");
                    setError("");
                    setTimeout(() => {
                        navigate("/dashboard");
                    }, 2000);
                })
                .catch((err) => {
                    setError("Error al iniciar sesión. Verifica tus credenciales.");
                    setMessage("");
                });
        } else {
            setError("Por favor ingresa un correo y una contraseña.");
            setMessage("");
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            sx={{
                /* background: "linear-gradient(135deg,rgb(17, 45, 255),rgb(37, 13, 255))", */
            }}
        >
            <Card sx={{ padding: 4, maxWidth: 400, width: "100%", boxShadow: 5, borderRadius: 3, background: "#f5f5f5" }}>
                <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <img src={LOGO_BASE64} alt="Logo" style={{ width: "80%", marginBottom: "10px" }} />
                        <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
                            {isRegister ? "Registro de Usuario" : "Iniciar Sesión"}
                        </Typography>

                        {message && (
                            <Alert severity="success" sx={{ mb: 2 }}>
                                {message}
                            </Alert>
                        )}
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}

                        {isRegister ? (
                            <Register />
                        ) : (
                            <>
                                <TextField
                                    label="Correo electrónico"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    label="Contraseña"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: "#01579b", color: "white", '&:hover': { backgroundColor: "#014377" } }}
                                    fullWidth
                                    onClick={handleLogin}
                                >
                                    Iniciar Sesión
                                </Button>
                            </>
                        )}

                        <Box sx={{ mt: 2 }}>
                            {isRegister ? (
                                <Typography>
                                    ¿Ya tienes una cuenta?{" "}
                                    <Link
                                        onClick={() => setIsRegister(false)}
                                        sx={{ cursor: "pointer", color: "primary.main" }}
                                    >
                                        Inicia sesión
                                    </Link>
                                </Typography>
                            ) : (
                                <Typography>
                                    ¿No tienes una cuenta?{" "}
                                    <Link
                                        onClick={() => setIsRegister(true)}
                                        sx={{ cursor: "pointer", color: "primary.main" }}
                                    >
                                        Regístrate
                                    </Link>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Login;