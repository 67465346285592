import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, query, where, addDoc } from "firebase/firestore";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert, // Importamos Alert
} from "@mui/material";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Cotizacion = () => {
  const [empresas, setEmpresas] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [formData, setFormData] = useState({
    empresaId: "",
    unidadId: "",
    serviciosSeleccionados: [],
  });

  const [alert, setAlert] = useState({ message: "", severity: "" }); // Estado para las alertas

  // Cargar empresas y servicios desde Firebase
  useEffect(() => {
    const fetchData = async () => {
      try {
        const empresasSnap = await getDocs(collection(db, "empresas"));
        const serviciosSnap = await getDocs(collection(db, "servicios"));

        setEmpresas(empresasSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setServicios(serviciosSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    fetchData();
  }, []);

  // Cargar unidades filtradas por empresa
  useEffect(() => {
    const fetchUnidades = async () => {
      if (!formData.empresaId) {
        setUnidades([]);
        return;
      }

      try {
        const unidadesQuery = query(
          collection(db, "unidades"),
          where("id_empresa", "==", formData.empresaId)
        );
        const unidadesSnap = await getDocs(unidadesQuery);
        setUnidades(unidadesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error al obtener unidades:", error);
      }
    };

    fetchUnidades();
  }, [formData.empresaId]);

  // Manejar cambios en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  // Agregar servicio a la lista
  const handleAddService = () => {
    setFormData(prev => ({
      ...prev,
      serviciosSeleccionados: [...prev.serviciosSeleccionados, { servicioId: "", cantidad: 1, precio: 0 }]
    }));
  };

  // Manejar cambios en los servicios
  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...formData.serviciosSeleccionados];

    if (field === "servicioId") {
      const servicio = servicios.find(s => s.id === value);
      updatedServices[index].precio = servicio ? servicio.precio : 0;
    }

    updatedServices[index][field] = value;
    setFormData(prev => ({ ...prev, serviciosSeleccionados: updatedServices }));
  };

  // Calcular totales
  const subtotal = formData.serviciosSeleccionados.reduce(
    (acc, servicio) => acc + servicio.precio * servicio.cantidad,
    0
  );
  const iva = subtotal * 0.16;
  const total = subtotal + iva;

  // Guardar cotización en Firebase
  const handleSubmit = async () => {
    // Validar si no hay unidades registradas y no se ha seleccionado ninguna unidad
    if (unidades.length === 0 && !formData.unidadId) {
      setAlert({ message: "No hay unidades registradas. Por favor, registre una unidad o solicite un servicio.", severity: "warning" });
      return;
    }

    // Validar si no se han seleccionado servicios
    if (formData.serviciosSeleccionados.length === 0) {
      setAlert({ message: "Debe seleccionar al menos un servicio.", severity: "error" });
      return;
    }

    try {
      await addDoc(collection(db, "cotizaciones"), { ...formData, subtotal, iva, total });
      setAlert({ message: "Cotización guardada correctamente", severity: "success" });
    } catch (error) {
      setAlert({ message: `Error al guardar la cotización: ${error.message}`, severity: "error" });
    }
  };

  // Generar PDF
  const generatePDF = () => {
    const doc = new jsPDF();

    doc.text("Cotización", 20, 20);

    // Verifica que los datos sean correctos antes de llamarlo
    doc.autoTable({
      head: [["Servicio", "Cantidad", "Precio Unitario", "Subtotal"]],
      body: formData.serviciosSeleccionados.map(s => {
        const servicio = servicios.find(serv => serv.id === s.servicioId) || {};
        const precio = Number(s.precio) || 0;
        const cantidad = Number(s.cantidad) || 0;
        return [
          servicio.nombre || "Desconocido",
          cantidad,
          `$${precio.toFixed(2)}`,
          `$${(precio * cantidad).toFixed(2)}`
        ];
      }),
    });

    doc.text(`Subtotal: $${subtotal.toFixed(2)}`, 20, doc.autoTable.previous.finalY + 10);
    doc.text(`IVA: $${iva.toFixed(2)}`, 20, doc.autoTable.previous.finalY + 20);
    doc.text(`Total: $${total.toFixed(2)}`, 20, doc.autoTable.previous.finalY + 30);

    doc.save("cotizacion.pdf");
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Cotización</Typography>

      {/* Mostrar el mensaje de alerta si existe */}
      {alert.message && (
        <Alert severity={alert.severity} sx={{ mb: 2 }}>
          {alert.message}
        </Alert>
      )}

      {/* Selección de Empresa y Unidad */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Empresa</InputLabel>
        <Select name="empresaId" value={formData.empresaId} onChange={handleChange}>
          {empresas.map(emp => <MenuItem key={emp.id} value={emp.id}>{emp.nombre}</MenuItem>)}
        </Select>
      </FormControl>

      {/* Mostrar unidades disponibles o mostrar botones de acciones */}
      {unidades.length > 0 ? (
        <FormControl fullWidth margin="normal">
          <InputLabel>Unidad</InputLabel>
          <Select name="unidadId" value={formData.unidadId} onChange={handleChange}>
            {unidades.map(uni => (
              <MenuItem key={uni.id} value={uni.id}>
                {`${uni.año} - ${uni.marca} - ${uni.numero} - ${uni.placa}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <div>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            No hay unidades registradas para esta empresa.
          </Typography>
          <Button variant="outlined" color="primary" onClick={() => alert("Registrando una unidad...")}>
            Registrar Unidad
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => alert("Solicitando un servicio...")}>
            Solicitar Servicio
          </Button>
        </div>
      )}

      {/* Tabla de Servicios */}
      <Button onClick={handleAddService} variant="contained" color="secondary">Agregar Servicio</Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Servicio</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Precio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData.serviciosSeleccionados.map((serv, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Select
                    value={serv.servicioId}
                    onChange={e => handleServiceChange(index, "servicioId", e.target.value)}
                  >
                    <MenuItem value="" disabled>Selecciona un servicio...</MenuItem>
                    {servicios.map(s => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>

                <TableCell>
                  <TextField type="number" value={serv.cantidad} onChange={e => handleServiceChange(index, "cantidad", Number(e.target.value))} />
                </TableCell>
                <TableCell>${serv.precio}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6">Total: ${total.toFixed(2)}</Typography>

      <Button onClick={handleSubmit} variant="contained" color="primary">Guardar</Button>
      <Button onClick={generatePDF} variant="contained" color="secondary">Generar PDF</Button>
    </Container>
  );
};

export default Cotizacion;
