import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Typography, Snackbar, Alert, MenuItem, Tabs, Tab, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { db } from "../firebaseConfig";
import { collection, addDoc, getDocs, onSnapshot } from "firebase/firestore";

const RegistroServiciostipoServicios = () => {
  const [formDatatipoServicios, setFormDatatipoServicios] = useState({ nombre: "", descripcion: "", abreviatura: "" });
  const [formDataServicio, setFormDataServicio] = useState({ nombre: "", descripcion: "", id_tipoServicios: "", precio: "" });
  const [tipoServicios, settipoServicios] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [alertInfo, setAlertInfo] = useState({ message: "", severity: "success" });
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  // useEffect para obtener los datos de tipoServicios y servicios
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener tipos de servicio
        const tipoServiciosCollection = collection(db, "tipoServicios");
        const tipoServiciosSnapshot = await getDocs(tipoServiciosCollection);
        if (!tipoServiciosSnapshot.empty) {
          settipoServicios(tipoServiciosSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        } else {
          console.log("No hay tipos de servicio disponibles.");
        }

        // Obtener servicios
        const serviciosCollection = collection(db, "servicios");
        const serviciosSnapshot = await getDocs(serviciosCollection);
        if (!serviciosSnapshot.empty) {
          setServicios(serviciosSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        } else {
          console.log("No hay servicios disponibles.");
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };
    fetchData();
  }, []);

  // useEffect para escuchar cambios en tiempo real
  useEffect(() => {
    const unsubscribetipoServicios = onSnapshot(collection(db, "tipoServicios"), (tipoServiciosSnapshot) => {
      settipoServicios(tipoServiciosSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    const unsubscribeServicios = onSnapshot(collection(db, "servicios"), (serviciosSnapshot) => {
      setServicios(serviciosSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    // Cleanup function para cancelar la suscripción cuando el componente se desmonte
    return () => {
      unsubscribetipoServicios();
      unsubscribeServicios();
    };
  }, []);

  const handleChange = (setState) => (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmittipoServicios = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "tipoServicios"), formDatatipoServicios);
      setAlertInfo({ message: "Tipo de servicio registrado correctamente", severity: "success" });
      setOpen(true);
      setFormDatatipoServicios({ nombre: "", descripcion: "", abreviatura: "" });
    } catch (error) {
      setAlertInfo({ message: "Error al registrar el tipo de servicio", severity: "error" });
      setOpen(true);
    }
  };

  const handleSubmitServicio = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "servicios"), formDataServicio);
      setAlertInfo({ message: "Servicio registrado correctamente", severity: "success" });
      setOpen(true);
      setFormDataServicio({ nombre: "", descripcion: "", id_tipoServicios: "", precio: "" });
    } catch (error) {
      setAlertInfo({ message: "Error al registrar el servicio", severity: "error" });
      setOpen(true);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Registro de Servicios y Tipos de Servicios</Typography>
      <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
        <Tab label="Tipo de Servicio" />
        <Tab label="Servicio" />
      </Tabs>

      {/* Formulario para Tipo de Servicio */}
      <Box hidden={tabIndex !== 0} sx={{ mt: 2 }}>
        <TableContainer component={Paper} style={{ marginBottom: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Abreviatura</TableCell>            
              </TableRow>
            </TableHead>
            <TableBody>
              {tipoServicios.length > 0 ? (
                tipoServicios.map((tiposervicio) => (
                  <TableRow key={tiposervicio.id}>
                    <TableCell>{tiposervicio.nombre}</TableCell>
                    <TableCell>{tiposervicio.abreviatura}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">No hay tipos de servicio registrados</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <form onSubmit={handleSubmittipoServicios}>
          <TextField fullWidth label="Nombre" name="nombre" value={formDatatipoServicios.nombre} onChange={handleChange(setFormDatatipoServicios)} required sx={{ mb: 2 }} />
          <TextField fullWidth label="Descripción" name="descripcion" value={formDatatipoServicios.descripcion} onChange={handleChange(setFormDatatipoServicios)} sx={{ mb: 2 }} />
          <TextField fullWidth label="Abreviatura" name="abreviatura" value={formDatatipoServicios.abreviatura} onChange={handleChange(setFormDatatipoServicios)} required sx={{ mb: 2 }} />
          <Button type="submit" variant="contained" color="primary" fullWidth>Registrar Tipo de Servicio</Button>
        </form>
      </Box>

      {/* Formulario para Servicio */}
      <Box hidden={tabIndex !== 1} sx={{ mt: 2 }}>
        <TableContainer component={Paper} style={{ marginBottom: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Tipo de Servicio</TableCell>
                <TableCell>Precio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {servicios.length > 0 ? (
                servicios.map((servicio) => (
                  <TableRow key={servicio.id}>
                    <TableCell>{servicio.nombre}</TableCell>
                    <TableCell>{servicio.descripcion}</TableCell>
                    <TableCell>{tipoServicios.find(tipo => tipo.id === servicio.id_tipoServicios)?.nombre}</TableCell>
                    <TableCell>{servicio.precio}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No hay servicios registrados</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <form onSubmit={handleSubmitServicio}>
          <TextField fullWidth label="Nombre" name="nombre" value={formDataServicio.nombre} onChange={handleChange(setFormDataServicio)} required sx={{ mb: 2 }} />
          <TextField fullWidth label="Descripción" name="descripcion" value={formDataServicio.descripcion} onChange={handleChange(setFormDataServicio)} sx={{ mb: 2 }} />
          <TextField fullWidth label="Tipo de Servicio" name="id_tipoServicios" value={formDataServicio.id_tipoServicios} onChange={handleChange(setFormDataServicio)} required select sx={{ mb: 2 }}>
            {tipoServicios.map((tipo) => (
              <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>
            ))}
          </TextField>
          <TextField fullWidth label="Precio" name="precio" value={formDataServicio.precio} onChange={handleChange(setFormDataServicio)} type="number" required sx={{ mb: 2 }} />
          <Button type="submit" variant="contained" color="primary" fullWidth>Registrar Servicio</Button>
        </form>
      </Box>

      {/* Notificación de éxito o error */}
      <Snackbar open={open} autoHideDuration={4000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setOpen(false)} severity={alertInfo.severity} sx={{ width: '100%' }}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RegistroServiciostipoServicios;

