import React, { createContext, useContext, useState, useEffect } from "react";
import { signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, storage } from "../firebaseConfig"; // Aquí importas storage desde firebaseConfig

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  const login = async ({ email, password }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      setError("");
      console.log("Usuario autenticado:", userCredential.user);
      return userCredential;
    } catch (error) {
      console.error("Error de autenticación:", error);
      setError("Error al iniciar sesión. Verifica tus credenciales.");
      throw error;
    }
  };

  const register = async ({ email, password, name }) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, { displayName: name });
      setUser(user);
      setError("");
      console.log("Usuario registrado:", user);
      return userCredential;
    } catch (error) {
      console.error("Error al registrar usuario:", error);
      setError("Error al registrar el usuario. Verifica los datos.");
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      console.log("Usuario desconectado");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const updateAvatar = async (file) => {
    try {
      const storageRef = ref(storage, `avatars/${user.uid}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      await updateProfile(auth.currentUser, { photoURL: downloadURL });
      setUser((prevUser) => ({ ...prevUser, photoURL: downloadURL }));
    } catch (error) {
      console.error("Error al actualizar el avatar:", error);
      throw error;
    }
  };

  const updateDisplayName = async (newName) => {
    if (auth.currentUser) {
      await updateProfile(auth.currentUser, { displayName: newName });
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, register, updateAvatar, updateDisplayName, error }}>
      {children}
    </AuthContext.Provider>
  );
};
