import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Typography, Snackbar, Alert, MenuItem, Tabs, Tab, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { db } from "../firebaseConfig";
import { collection, getDocs, onSnapshot, addDoc } from "firebase/firestore";

const RegistroUnidadTipoUnidad = () => {
  const [formDataTipoUnidad, setFormDataTipoUnidad] = useState({ nombre: "", descripcion: "", abreviatura: "" });
  const [formDataUnidad, setFormDataUnidad] = useState({ id_tipounidad: "", numero: "", placa: "", marca: "", año: "", id_empresa: "" });
  const [empresas, setEmpresas] = useState([]);
  const [tiposUnidad, setTiposUnidad] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [alertInfo, setAlertInfo] = useState({ message: "", severity: "success" });
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  // useEffect para obtener los datos inicialmente
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener empresas
        const empresasCollection = collection(db, "empresas");
        const empresasSnapshot = await getDocs(empresasCollection);
        if (!empresasSnapshot.empty) {
          setEmpresas(empresasSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        } else {
          console.log("No hay empresas disponibles.");
        }

        // Obtener tipos de unidad
        const tiposUnidadCollection = collection(db, "tipoUnidad");
        const tiposUnidadSnapshot = await getDocs(tiposUnidadCollection);
        if (!tiposUnidadSnapshot.empty) {
          setTiposUnidad(tiposUnidadSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        } else {
          console.log("No hay tipos de unidad disponibles.");
        }

        // Obtener unidades
        const unidadesCollection = collection(db, "unidades");
        const unidadesSnapshot = await getDocs(unidadesCollection);
        if (!unidadesSnapshot.empty) {
          setUnidades(unidadesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        } else {
          console.log("No hay unidades disponibles.");
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, []);

  // useEffect para escuchar cambios en tiempo real
  useEffect(() => {
    const unsubscribeEmpresas = onSnapshot(collection(db, "empresas"), (empresasSnapshot) => {
      setEmpresas(empresasSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    const unsubscribeTiposUnidad = onSnapshot(collection(db, "tipoUnidad"), (tiposUnidadSnapshot) => {
      setTiposUnidad(tiposUnidadSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    const unsubscribeUnidades = onSnapshot(collection(db, "unidades"), (unidadesSnapshot) => {
      setUnidades(unidadesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return () => {
      unsubscribeEmpresas();
      unsubscribeTiposUnidad();
      unsubscribeUnidades();
    };
  }, []);

  const handleChange = (setState) => (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmitTipoUnidad = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "tipoUnidad"), formDataTipoUnidad);
      setAlertInfo({ message: "Tipo de unidad registrado correctamente", severity: "success" });
      setOpen(true);
      setFormDataTipoUnidad({ nombre: "", descripcion: "", abreviatura: "" });
    } catch (error) {
      setAlertInfo({ message: "Error al registrar el tipo de unidad", severity: "error" });
      setOpen(true);
    }
  };

  const handleSubmitUnidad = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "unidades"), formDataUnidad);
      setAlertInfo({ message: "Unidad registrada correctamente", severity: "success" });
      setOpen(true);
      setFormDataUnidad({ id_tipounidad: "", numero: "", placa: "", marca: "", año: "", id_empresa: "" });
    } catch (error) {
      setAlertInfo({ message: "Error al registrar la unidad", severity: "error" });
      setOpen(true);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Registro de Tipo de Unidad y Unidad</Typography>
      <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
        <Tab label="Tipo de Unidad" />
        <Tab label="Unidad" />
      </Tabs>

      {/* Formulario para Tipo de Unidad */}
      <Box hidden={tabIndex !== 0} sx={{ mt: 2 }}>
         {/* Tabla de Tipos de Unidad */}
      <Typography variant="h6" sx={{ mt: 4 }}>Tipos de Unidad</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Abreviatura</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiposUnidad.map((tipo) => (
              <TableRow key={tipo.id}>
                <TableCell>{tipo.nombre}</TableCell>
                <TableCell>{tipo.descripcion}</TableCell>
                <TableCell>{tipo.abreviatura}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        <form onSubmit={handleSubmitTipoUnidad}>
          <TextField fullWidth label="Nombre" name="nombre" value={formDataTipoUnidad.nombre} onChange={handleChange(setFormDataTipoUnidad)} required sx={{ mb: 2 }} />
          <TextField fullWidth label="Descripción" name="descripcion" value={formDataTipoUnidad.descripcion} onChange={handleChange(setFormDataTipoUnidad)} sx={{ mb: 2 }} />
          <TextField fullWidth label="Abreviatura" name="abreviatura" value={formDataTipoUnidad.abreviatura} onChange={handleChange(setFormDataTipoUnidad)} required sx={{ mb: 2 }} />
          <Button type="submit" variant="contained" color="primary" fullWidth>Registrar Tipo de Unidad</Button>
        </form>
      </Box>

      {/* Formulario para Unidad */}
      <Box hidden={tabIndex !== 1} sx={{ mt: 2 }}>
              {/* Tabla de Unidades */}
      <Typography variant="h6" sx={{ mt: 4 }}>Unidades</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo de Unidad</TableCell>
              <TableCell>Número</TableCell>
              <TableCell>Placa</TableCell>
              <TableCell>Marca</TableCell>
              <TableCell>Año</TableCell>
              <TableCell>Empresa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unidades.map((unidad) => (
              <TableRow key={unidad.id}>
                <TableCell>{tiposUnidad.find((tipo) => tipo.id === unidad.id_tipounidad)?.nombre}</TableCell>
                <TableCell>{unidad.numero}</TableCell>
                <TableCell>{unidad.placa}</TableCell>
                <TableCell>{unidad.marca}</TableCell>
                <TableCell>{unidad.año}</TableCell>
                <TableCell>{empresas.find((empresa) => empresa.id === unidad.id_empresa)?.nombre}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        <form onSubmit={handleSubmitUnidad}>
          <TextField fullWidth label="Tipo de Unidad" name="id_tipounidad" value={formDataUnidad.id_tipounidad} onChange={handleChange(setFormDataUnidad)} required select sx={{ mb: 2 }}>
            {tiposUnidad.map((tipo) => (
              <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>
            ))}
          </TextField>
          <TextField fullWidth label="Número" name="numero" value={formDataUnidad.numero} onChange={handleChange(setFormDataUnidad)} required sx={{ mb: 2 }} />
          <TextField fullWidth label="Placa" name="placa" value={formDataUnidad.placa} onChange={handleChange(setFormDataUnidad)} required sx={{ mb: 2 }} />
          <TextField fullWidth label="Marca" name="marca" value={formDataUnidad.marca} onChange={handleChange(setFormDataUnidad)} required sx={{ mb: 2 }} />
          <TextField fullWidth label="Año" name="año" type="number" value={formDataUnidad.año} onChange={handleChange(setFormDataUnidad)} required sx={{ mb: 2 }} />
          <TextField fullWidth label="Empresa" name="id_empresa" value={formDataUnidad.id_empresa} onChange={handleChange(setFormDataUnidad)} required select sx={{ mb: 2 }}>
            {empresas.map((empresa) => (
              <MenuItem key={empresa.id} value={empresa.id}>{empresa.nombre}</MenuItem>
            ))}
          </TextField>
          <Button type="submit" variant="contained" color="primary" fullWidth>Registrar Unidad</Button>
        </form>
      </Box>

     


      {/* Notificación de éxito o error */}
      <Snackbar open={open} autoHideDuration={4000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setOpen(false)} severity={alertInfo.severity} sx={{ width: '100%' }}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RegistroUnidadTipoUnidad;
