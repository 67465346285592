import React from "react";
import { Routes, Route, Navigate } from "react-router-dom"; 
import { useAuth } from "./context/AuthContext"; 
import Login from "./pages/Login"; 
import Dashboard from "./pages/Dashboard"; 
import Register from "./pages/Register";

const App = () => {
  const { user } = useAuth();

  if (user === undefined) {
    return <div>Cargando...</div>; // Muestra un loader mientras se obtiene el usuario
  }

  return (
    <Routes>
      <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Login />} />
      <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/" />} />
      <Route path="/register" element={<Register />} />
    </Routes>
  );
};


export default App;
