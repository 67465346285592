import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Importa la configuración de Firebase

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [rolesList, setRolesList] = useState([]); // Lista de roles desde Firestore
  const [error, setError] = useState("");
  const [loadingRoles, setLoadingRoles] = useState(true); // Estado de carga
  const [rolesCargados, setRolesCargados] = useState(false); // Estado para indicar que los roles están listos

  const { register } = useAuth();
  const navigate = useNavigate();

  // Función para obtener los roles desde Firestore
  const fetchRoles = async () => {
    try {
      console.log("Obteniendo roles desde Firestore...");
      const querySnapshot = await getDocs(collection(db, "rol"));
      if (!querySnapshot.empty) {
        const roles = querySnapshot.docs.map((doc) => {
          console.log("Documento obtenido:", doc.data()); // 🔍 Verifica los datos
          return { id: doc.id, nombre: doc.data().nombre }; // Asegúrate de que el campo es 'nombre'
        });

        setRolesList(roles);
      } else {
        console.warn("No se encontraron roles en Firestore.");
      }
    } catch (error) {
      console.error("Error al obtener los roles:", error);
    } finally {
      setLoadingRoles(false); // ✅ Detener la carga después de la petición
      setRolesCargados(true); // Marcar los roles como cargados
    }
  };

  // Cargar roles cuando el componente se monte
  useEffect(() => {
    if (!rolesCargados) {
      fetchRoles();
    }
  }, [rolesCargados]); // Dependencia de 'rolesCargados' para evitar recargas innecesarias

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!role) {
      setError("Debe seleccionar un rol antes de registrar el usuario.");
      return;
    }

    try {
      await register({ email, password, name, role });
      alert("Usuario registrado exitosamente");
      navigate("/login");
    } catch (error) {
      setError("Error al registrar el usuario. Verifica los datos.");
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Registro de Usuario</h2>
      <form onSubmit={handleRegister}>
        <TextField
          label="Nombre Completo"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Correo Electrónico"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Contraseña"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <FormControl fullWidth margin="normal" required error={!role && error}>
          <InputLabel>Rol de Usuario</InputLabel>
          <Select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            label="Rol de Usuario"
            disabled={loadingRoles} // Deshabilitar select mientras se cargan los roles
          >
            {loadingRoles ? (
              <MenuItem disabled>Cargando roles...</MenuItem>
            ) : rolesList.length > 0 ? (
              rolesList.map((r) => (
                <MenuItem key={r.id} value={r.nombre}>{r.nombre}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No hay roles disponibles</MenuItem>
            )}
          </Select>
          <FormHelperText>{error ? error : "Seleccione el rol del usuario"}</FormHelperText>
        </FormControl>

        {error && <p style={{ color: "red" }}>{error}</p>}

        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loadingRoles}>
          Registrar
        </Button>
      </form>
    </div>
  );
};

export default Register;
