import React, { useState } from "react";
import {
  Box,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
} from "@mui/material";
import { Menu as MenuIcon, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { LOGO_BASE64 } from "../components/constants";

const Sidebar = ({ onContentChange, mobileOpen, setMobileOpen }) => {
  const [openServices, setOpenServices] = useState(false);
  const isMobile = useMediaQuery("(max-width:768px)");

  const handleServicesClick = () => setOpenServices(!openServices);

  // Función para cerrar el drawer en dispositivos móviles
  const handleDrawerClose = () => setMobileOpen(false);

  return (
    <>
      {/* Mostrar el ícono del menú solo en dispositivos móviles */}
      {isMobile && (
        <IconButton onClick={() => setMobileOpen(!mobileOpen)} sx={{ position: "absolute", top: 16, left: 16 }}>
          <MenuIcon />
        </IconButton>
      )}

      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerClose}
        sx={{
          width: isMobile ? "auto" : 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": { width: 240, boxSizing: "border-box" },
        }}
      >
        <Box p={2} textAlign="center">
          <img src={LOGO_BASE64} alt="Logo" style={{ width: "80%", marginBottom: "10px" }} />
        </Box>
        <Divider />
        <List>
          <ListItem button onClick={() => onContentChange("dashboard")}>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }} onClick={() => onContentChange("RegistroContactos")}>
            <ListItemText primary="Registro Contactos" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }} onClick={() => onContentChange("RegistroEmpresa")}>
            <ListItemText primary="Registro Empresa" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }} onClick={() => onContentChange("RegistroUnidadTipoUnidad")}>
            <ListItemText primary="Registro Unidad y Tipo de Unidad" />
          </ListItem>

          <ListItem button onClick={handleServicesClick}>
            <ListItemText primary="Servicios" />
            {openServices ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openServices} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button sx={{ pl: 4 }} onClick={() => onContentChange("RegistroServicios")}>
                <ListItemText primary="Registro de Servicios" />
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => onContentChange("RegistroRoles")}>
                <ListItemText primary="Registro de Roles" />
              </ListItem>
              <ListItem button sx={{ pl: 4 }} onClick={() => onContentChange("Cotizacion")}>
                <ListItemText primary="Cotización" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
