import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Typography, Grid, Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from "@mui/material";
import { db } from "../firebaseConfig";  // Asegúrate de que esta importación esté bien
import { collection, addDoc, getDocs } from "firebase/firestore"; // Importa las funciones necesarias de Firestore

const RegistroRoles = () => {
  const [formData, setFormData] = useState({
    nombre: "",
  });

  const [rol, setRol] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ message: "", severity: "success" });
  const [loading, setLoading] = useState(false);  // Estado para el loading

  // Maneja los cambios en los campos del formulario
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Validación de los campos
  const validateForm = () => {
   
    if (!formData.nombre ) {
      setAlertInfo({ message: "Todos los campos son obligatorios", severity: "error" });
      setOpen(true);
      return false;
    }

    return true;
  };

  // Función para guardar la rol en Firestore
  const saveRolToFirestore = async (rolData) => {
    try {
      // Agregar la rol a la colección 'rol' en Firestore
      const docRef = await addDoc(collection(db, "rol"), rolData);
      console.log("rol registrada con ID: ", docRef.id);
    } catch (e) {
      console.error("Error añadiendo documento: ", e);
      throw new Error("Error al registrar la rol en la base de datos.");
    }
  };

  // Función que maneja el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true); // Inicia el loading

    const rolData = {
      nombre: formData.nombre   
    };

    try {
      // Guardar los datos de la rol en Firestore
      await saveRolToFirestore(rolData);

      setAlertInfo({ message: "rol registrada correctamente", severity: "success" });
      setOpen(true);
      setFormData({ nombre: ""});
    } catch (error) {
      console.error("Error en handleSubmit:", error);
      setAlertInfo({ message: error.message, severity: "error" });
      setOpen(true);
    } finally {
      setLoading(false); // Detiene el loading
    }
  };

  // Función para cargar todas las rol registradas
  const fetchRol = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "rol"));
      const RolList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setRol(RolList);
    } catch (error) {
      console.error("Error al cargar rol:", error);
    }
  };

  // Usamos useEffect para cargar las rol solo una vez al montar el componente
  useEffect(() => {
    fetchRol();
  }, []);  // Dependencia vacía para que se ejecute solo una vez

  // Cerrar el Snackbar
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="md" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "100vh" }}>
      <Typography variant="h4" gutterBottom>
        Registro de Roles
      </Typography>

      {/* Mostrar tabla de rol registradas */}
      <TableContainer component={Paper} style={{ marginBottom: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>             
            </TableRow>
          </TableHead>
          <TableBody>
            {rol.length > 0 ? (
              rol.map((rol) => (
                <TableRow key={rol.id}>
                  <TableCell>{rol.nombre}</TableCell>                
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">No hay rol registradas</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Formulario de registro */}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label="Nombre" name="nombre" value={formData.nombre} onChange={handleChange} required />
          </Grid>        
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : "Registrar"}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Snackbar para mostrar mensajes */}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={alertInfo.severity} sx={{ width: '100%' }}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RegistroRoles;
